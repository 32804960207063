import Vue from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

let relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(relativeTime).locale('pt-br')

Vue.mixin({
	methods: {
		format: date => dayjs(date).utc().format('DD/MM/YYYY'),
		hour: date => dayjs(date).format('HH:mm'),
		timeTo: date => dayjs().to(dayjs(date)),
		timeFrom: date => dayjs().from(dayjs(date))
	}
})

<template>
	<div class="placeholder weather__temperature">
		<b-skeleton height="20" width="30"></b-skeleton>
		<b-skeleton height="10" width="100%"></b-skeleton>
	</div>
</template>

<script>
export default {
	name: 'Placeholder'
}
</script>
